import React from "react";
import { Link } from "gatsby";

const CallToAction = () => (
  <div className="bg-white">
    <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <p className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
        Vous avez des besoins en électricité, climatisation ou VMC ? Contactez nous!
      </p>
      <div className="mt-8 flex justify-center flex-wrap">
        <div className="inline-flex shadow-xl mb-1">
          <Link
            to="/contactez-nous/"
            title="Contactez-nous"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            <svg
              className="h-6 w-6 pr-1 inline-block"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>{" "}
            Demander un devis
          </Link>
        </div>
        <div className="sm:ml-3 inline-flex mb-1">
          <a
            href="tel:0033582952867"
            title="Appelez nous"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium shadow-xl text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
          >
            <svg
              className="h-6 w-6 pr-1 inline-block"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            05 82 95 28 67
          </a>
        </div>
      </div>
    </div>
  </div>
);


export default CallToAction;