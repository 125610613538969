import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Content, { HTMLContent } from "../components/content";
import CallToAction from "../components/calltoaction";

import BackgroundImage from "gatsby-background-image";

const ContentPageTemplate = ({
  title,
  backgroundImageFile,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <BackgroundImage
        Tag="div"
        className="mb-10 bg-opacity-50 h-32 sm:h-64 bg-cover bg-no-repeat bg-center flex items-center content-center justify-center"
        fluid={backgroundImageFile.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <h1 className="text-gray-900 text-center text-3xl sm:text-6xl px-4 sm:px-6 bg-blue-100 bg-opacity-75">{title}</h1>
      </BackgroundImage>
      <section className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <article class="prose max-w-none lg:prose-lg">
          <PageContent content={content} />
        </article>
      </section>
      <CallToAction />
    </div>
  );
};

ContentPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  background_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ContentPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <Layout>
      <SEO
        title={
          page.frontmatter.meta_title
            ? page.frontmatter.meta_title
            : page.frontmatter.title
        }
        description={
          page.frontmatter.meta_description
            ? page.frontmatter.meta_description
            : ""
        }
      />
      <ContentPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        backgroundImageFile={page.frontmatter.background_image}
        content={page.html}
      />
    </Layout>
  );
};

ContentPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContentPage;

export const pageQuery = graphql`
  query ContentPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        background_image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        meta_title
        meta_description
      }
    }
  }
`;
